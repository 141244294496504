export const initTabKeyListener = () => {
  window.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-active')
  })

  window.addEventListener('keydown', (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add('keyboard-active')
    }
  })
}
