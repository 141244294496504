/**
 * Hotfix for sal.js
 * https://github.com/mciastek/sal/issues/62
 */
const customSalScrollAnimationHandler = () => {
  const theSals = document.querySelectorAll('[data-sal]')
  if (theSals.length) {
    Array.prototype.forEach.call(theSals, function (e) {
      const salPos = e.getBoundingClientRect().top
      const percentOfWindowHeight = window.innerHeight * 0.9
      if (salPos < percentOfWindowHeight) {
        e.classList.add('sal-animate')
      }
    })
  }
}

export default () => {
  window.addEventListener('load', customSalScrollAnimationHandler)
  window.addEventListener('scroll', customSalScrollAnimationHandler)
}
